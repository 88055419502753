<script lang="ts" setup>
const { searchContent, content, loading } = useContent('homepage')
const { setSeoHomepage } = useSEO()
const { reportPageView } = useTagManager()
const { setupUrls } = useAlternativePageUrls()
const { getURL } = useURL()

useCache('homepage')

setupUrls((locale) => getURL({ name: 'index' }, locale))

const contentKey = 'homepage'

reportPageView('home')
setSeoHomepage()
await searchContent({ keys: [contentKey] })
const slotValue = computed(() => content.value[contentKey])
</script>
<template>
  <ContentPage
    v-if="slotValue"
    :content="slotValue"
    :content-key="contentKey"
    :loading="loading"
    class="asf-homepage"
    v-e2e="'homepage-content-area'"
  >
    <template #content-product-carousel>
      <AsfWrapper tag="section">
        <AppLastVisitedProducts />
      </AsfWrapper>
    </template>
  </ContentPage>
</template>
<style lang="postcss">
@import 'assets/css/pages/homepage.css';
</style>
